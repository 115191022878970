import { isValid } from 'date-fns'
import isNumber from 'lodash.isnumber'

export const requiredNotNegative = (val: number): boolean | string =>
  val > -1 || 'Obligatorisk'

export const requiredNotLessThenZero = (val: number): boolean | string =>
  val > 0 || 'Obligatorisk'

export const requiredNotNull = (val: unknown | null): boolean | string =>
  val !== null || 'Obligatorisk'

export const requiredNotNumber = (val: number): boolean | string =>
  isNumber(val) || 'Obligatorisk'

export const required = (val: string | number | null): boolean | string =>
  Boolean(val && val.toString().length > 0) || 'Obligatorisk'

export const booleanTrueRequired = (val: boolean): boolean | string =>
  val || 'Obligatorisk'

export const validDateRule = (val: string): boolean | string =>
  isValid(new Date(val)) || 'Måste vara ett giltigt datum'

export const minCharactersRule =
  (minChar: number) =>
  (val: string | number | null): boolean | string =>
    Boolean(val && val.toString().length >= minChar) ||
    `Måste minst vara ${minChar} tecken`

export const requiredNotEmpty = (val: unknown[]): boolean | string =>
  Boolean(val.length) || 'Obligatorisk'
